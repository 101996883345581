'use strict'

###*
 # @ngdoc service
 # @name mundoNotifications.factory:MundoNotifications

 # @description

###
angular
  .module 'mundoNotifications'
  .factory 'MundoNotifications', [
    'MundoSocket'
    '$rootScope'
    '$log'
    '$mdToast'
    '$window'
    '$translate'
    (MundoSocket, $rootScope, $log, $mdToast, $window, $translate) ->
      Mustache = $window.Mustache

      MundoNotificationsBase = {}
      MundoNotificationsBase.options =
        toasts:
          enabled: false

      $rootScope
        .$on MundoSocket.options.tenantEventConsumer.dataEventName, (event, data) ->
          if MundoNotificationsBase.options.toasts.enabled
            if data.event.metadata.dataType == 'notification'
              MundoNotificationsBase
                .showToast data.event

      MundoNotificationsBase.enableToasts = () ->
        if @areToastsEnabled()
          @disableToasts()

        @options.toasts.enabled = true

      MundoNotificationsBase.disableToasts = () ->
        if not @areToastsEnabled()
          @enableToasts()

        @options.toasts.enabled = false

      MundoNotificationsBase.areToastsEnabled = () ->
        return !!@options.toasts.enabled

      MundoNotificationsBase.renderTemplateContent = (template, context) ->
        return $translate.instant "messages.notifications.#{template}", context
        # return Mustache.render translated, context

      MundoNotificationsBase.showToast = (data) ->
        heading = @renderTemplateContent data.label, data.parameters
        content = @renderTemplateContent data.comments, data.parameters

        $mdToast
          .show
            hideDelay: 4500
            position: 'bottom right'
            templateUrl: 'mundo-notifications/views/mundo-notification-toast.tpl.html'
            controller: [
              '$scope'
              '$mdToast'
              ($scope, $mdToast) ->
                $scope.content = content
                $scope.heading = heading
                $scope.close = () ->
                  $mdToast
                    .hide()
            ]

      MundoNotificationsBase
  ]
